import React from 'react';
import './App.css';

function Logo() {
  return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 1210.22 1006.92" style={{ width: 92, height: 92 }}>
    <path d="M983.28,638.8c-47-96.86-45.9-210.47-45.9-210.47H347.59s1.14,113.61-45.9,210.47S160.55,889.94,160.55,967.67v116h963.87v-116C1124.42,889.94,1030.33,735.67,983.28,638.8Z" transform="translate(-38.57 -136.54)" fill="#2784c7"/>
    <path d="M122,947.13h963.87a0,0,0,0,1,0,0v49.08a10.71,10.71,0,0,1-10.71,10.71H132.69A10.71,10.71,0,0,1,122,996.21V947.13a0,0,0,0,1,0,0Z" fill="#222f3e"/>
    <path d="M1221,296c-16.08-19.92-36-36.38-57.93-49.54-37-22.18-110.75-59.49-216.82-81.17,0,0-96.19-25.11-302.56-28.7-206.37,3.59-302.55,28.7-302.55,28.7-108.18,22.11-182.74,60.48-219,82.48a214,214,0,0,0-52.29,44C38.57,328.52,38.57,353,38.57,353V472.58c0,18.63,9.55,31,19.9,38.91C69.5,519.92,83.25,524,97.13,524h161.8c13.12,0,23.2-8.93,30.49-19.34,10.11-14.43,15-31.89,15-49.51v-61.5c0-14.35,28-25.11,28-25.11h609.5c11.64,0,23.19,3.52,32.23,10.86,4.92,4,8.85,8.87,8.85,14.25V456a84.42,84.42,0,0,0,10.93,42.28c7.44,12.86,18.8,25.71,34.54,25.71H1190c15.17,0,30.17-4.87,41.66-14.78,9.21-8,17.1-19.7,17.1-36.64V353S1248.79,330.36,1221,296Z" transform="translate(-38.57 -136.54)" fill="#2784c7"/>
    <polygon points="484.33 294.18 416.16 294.18 435.3 204.49 466.39 204.49 484.33 294.18" fill="#222f3e"/>
    <polygon points="795.25 294.18 727.09 294.18 746.22 204.49 777.31 204.49 795.25 294.18" fill="#222f3e"/>
    <circle cx="642.09" cy="773.54" r="233.99" transform="translate(-397.48 544.05) rotate(-45)" fill="#fff"/>
    <circle cx="645.87" cy="777.72" r="75.94" transform="translate(-399.33 547.95) rotate(-45)" fill="#222f3e"/>
    <circle cx="642.49" cy="621.66" r="42.25" transform="matrix(0.12, -0.99, 0.99, 0.12, -91.59, 1046.84)" fill="none" stroke="#2784c7" strokeMiterlimit="10" strokeWidth="12.76"/>
    <circle cx="642.49" cy="933.79" r="42.25" transform="translate(-528.19 852.86) rotate(-58.28)" fill="none" stroke="#2784c7" strokeMiterlimit="10" strokeWidth="12.76"/>
    <circle cx="752.84" cy="668.59" r="42.25" transform="translate(-290.84 591.63) rotate(-45)" fill="none" stroke="#2784c7" strokeMiterlimit="10" strokeWidth="12.76"/>
    <circle cx="799.74" cy="777.72" r="42.25" transform="matrix(0.29, -0.96, 0.96, 0.29, -214.94, 1181.24)" fill="none" stroke="#2784c7" strokeMiterlimit="10" strokeWidth="12.76"/>
    <circle cx="488.82" cy="777.72" r="42.25" transform="translate(-445.33 436.9) rotate(-45)" fill="none" stroke="#2784c7" strokeMiterlimit="10" strokeWidth="12.76"/>
    <circle cx="531.07" cy="668.59" r="42.25" transform="translate(-347.57 684.81) rotate(-61.85)" fill="none" stroke="#2784c7" strokeMiterlimit="10" strokeWidth="12.76"/>
    <circle cx="531.07" cy="886.85" r="42.25" transform="translate(-425.5 275.11) rotate(-31.72)" fill="none" stroke="#2784c7" strokeMiterlimit="10" strokeWidth="12.76"/>
    <path d="M830.17,970.12l0,0a21.38,21.38,0,0,1-30.9-.69l-78-85.28a16.29,16.29,0,0,1,.51-22.5h0a16.28,16.28,0,0,1,22.5-.5l85.23,78A21.39,21.39,0,0,1,830.17,970.12Z" transform="translate(-38.57 -136.54)" fill="#222f3e"/>
  </svg>
}

function App() {
  return (
    <div className="list">

      <div onClick={e => window.open("https://fluent.stream/phone/", "_blank")}>
        <header><Logo/></header>
        <h2>Phone</h2>
      </div>

      {/* <div onClick={e => window.open("https://my.fluentcloud.com/#userportal", "_blank")}>
        <header>???</header>
        <h2>User Portal</h2>
      </div>

      <div onClick={e => window.open("https://downloads.fluentcloud.com/mac/live-manager/latest", "_blank")}>
      <header>???</header>
        <h2>Live Manager</h2>
      </div>

      <div onClick={e => window.open("https://downloads.fluentcloud.com/mac/insights-react/latest", "_blank")}>
        <header>???</header>
        <h2>Insights</h2>
      </div>

      <div onClick={e => window.open("https://my.fluentcloud.com/#billingsettings", "_blank")}>
        <header>???</header>
        <h2>Billing</h2>
      </div>

      <div onClick={e => window.open("https://my.fluentcloud.com", "_blank")}>
        <header>???</header>
        <h2>Admin Console</h2>
      </div> */}

    </div>
  );
}

export default App;
